import * as React from "react"
import CaseStudyTemplate from "../components/case-study-template";
import { StaticImage } from "gatsby-plugin-image";

const content = {
	title: `L’Oréal Paris Men Expert`,
	heading: 'Red Bull x Woolworths.',
	emphasisedText: 'A Woolworths exclusive promo, that saw Men Expert’s biggest sales week in the history of the brand in Australia',
	text: [
		'We helped one lucky Men Expert customer win the ultimate energy experience — a once in a lifetime hot lap with the Red Bull Holden racing team! To enter, consumers had to purchase any two L’Oréal Men Expert products from Woolworths (in store or online), visit the site, upload their receipt and fill in their details. There were also 100 runner up prizes to be won.', 
		'With a strong in-store presence, this promotion gained traction from the get-go, and will be a likely repeat.'
	],
	numbers: {
		Entrants: '3000',
		Winners: '101',
		'In prizes': '$32K',
	},
};

const Image = () => <StaticImage alt="Hero image" src="../images/MenExpert3x.png" width={500} height={500} />;

export default function Loreal() {
	return (
		<CaseStudyTemplate 
			heading={content.heading}
			superHeading={content.title}
			image={<Image />}
			content={{
				title: content.emphasisedText,
				text: content.text,
			}}
			numbers={content.numbers}
		/>
	)
}
